<template>
  <Container v-if="qnaDetail" :style="{ padding: 0 }">
    <div class="pt-14 pb-10 px-10 mb-10 relative">
      <div>
        <div class="grid grid-cols-3">
          <div>
            <strong class="font-semibold block border-b border-gray-300 pb-2">상품정보</strong>
            <div class="flex items-start pt-2">
              <div class="w-20 h-20 bg-gray-100 bg-cover"
                   :style="{
                      backgroundImage: `url(${qnaDetail.product.imageUrl})`,
                    }"
              ></div>
              <div class="pl-4">
                <p class="font-semibold text-black mb-1 text-sm">LOT# {{ qnaDetail.product.lot || '-' }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.product.artistName || qnaDetail.product.brandName }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.product.title || '-' }}</p>
              </div>
            </div>
          </div>
          <div>
            <strong class="font-semibold block border-b border-gray-300 pb-2">회원정보</strong>
            <div class="flex items-start pt-2">
              <div class="rounded-full w-20 h-20 bg-gray-100 bg-cover"
                   :style="{
                      backgroundImage: `url(${qnaDetail.member.profileUrl})`,
                    }"
              ></div>
              <div class="pl-4">
                <p class="font-semibold text-black mb-1 text-sm">{{ qnaDetail.member.name || '-' }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.member.mobile || '-' }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.member.email || '-' }}</p>
              </div>
            </div>
          </div>
          <div>
            <strong class="font-semibold block border-b border-gray-300 pb-2">파트너정보</strong>
            <div class="flex items-start pt-2">
              <div class="rounded-full w-20 h-20 bg-gray-100 bg-cover"
                   :style="{
                      backgroundImage: `url(${qnaDetail.partner.profileUrl})`,
                    }"
              ></div>
              <div class="pl-4">
                <p class="font-semibold text-black mb-1 text-sm">{{ qnaDetail.partner.companyName || '-' }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.partner.csTelNo || '-' }}</p>
                <p class="text-black mb-1 text-sm text-black-100">{{ qnaDetail.partner.csEmail || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10">
          <strong class="font-semibold block mb-5">1:1문의 내역</strong>
          <div v-for="(qna, index) in qnaDetail.qnaList" :key="index">
            <div v-if="qna.type === 'question'" class="border-b border-gray-300 flex text-sm items-center ">
              <span class="text-gray-400 inline-block qna-width py-4">{{ qnaDetail.member.name }}</span>
              <div class="wide-memo qna-width py-4" v-html="qna.memo"></div>
              <span class="inline-flex items-center qna-width text-gray-400 text-xs pl-2">{{ formatDate(qna.updatedAt) }}</span>
            </div>
            <div v-else class="border-b border-gray-300 flex items-center text-sm">
              <span class="text-gray-400 inline-flex items-center justify-end qna-width p-2">
                <DownRightIcon></DownRightIcon>
              </span>
              <div class="with-memo py-2 items-center flex">
                <span class="text-gray-400 inline-block qna-width py-2">{{ qnaDetail.partner.companyName }}</span>
                <div class="wide-memo qna-width py-2" v-html="qna.memo"></div>
                <span class="inline-flex items-center qna-width text-gray-400 text-xs pl-2">{{ formatDate(qna.updatedAt) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/app/qna/list" class="mt-4 member-list__link flex items-center">
        <IconBase :width="24" :height="24" class="mr-1">
          <ArrowLeftIcon></ArrowLeftIcon>
        </IconBase>
        목록으로
      </router-link>
    </div>
  </Container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import DownRightIcon from '@/components/icons/DownRightIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'QnaDetail',
  components: {
    Container,
    IconBase,
    ArrowLeftIcon,
    DownRightIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const qnaDetail = ref<any>(null);
    const qnaQuery = ref({});
    const route = useRoute();

    const mobileFormat = (mobile) => {
      return mobile.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")
    }

    onMounted(async () => {
      // qnaQuery.value = route.query
      //   ? parseInt(route.params.memberId as string)
      //   : undefined;

      console.log(route, route.query.memberId)
      await fetchMemberInfo(route.query.memberId, route.query.productId);
    });

    const fetchMemberInfo = async (memberId, productId) => {
      try {
        const { data } = await partnerAPI.adminQna.adminQnaDetail({
          memberId,
          productId
        });
        qnaDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };


    return {
      qnaQuery,
      qnaDetail,
      mobileFormat,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  &:last-child {
    border-bottom: none;
  }
  &.with-border {
    border-bottom: 1px solid #ddd;
  }
  &.with-button {
    padding: 5px 0;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
.member-list__link {
  position: absolute;
  bottom: -60px;
  left: 0;
}
.qna-width {
  width: 144px;
  &.wide-memo {
    width: calc(100% - 288px);
    line-height: 1.2;
  }
}
.with-memo {
  width: calc(100% - 144px);
}
@import "../../../assets/style/label";
</style>
