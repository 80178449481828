
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import DownRightIcon from '@/components/icons/DownRightIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'QnaDetail',
  components: {
    Container,
    IconBase,
    ArrowLeftIcon,
    DownRightIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const qnaDetail = ref<any>(null);
    const qnaQuery = ref({});
    const route = useRoute();

    const mobileFormat = (mobile) => {
      return mobile.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")
    }

    onMounted(async () => {
      // qnaQuery.value = route.query
      //   ? parseInt(route.params.memberId as string)
      //   : undefined;

      console.log(route, route.query.memberId)
      await fetchMemberInfo(route.query.memberId, route.query.productId);
    });

    const fetchMemberInfo = async (memberId, productId) => {
      try {
        const { data } = await partnerAPI.adminQna.adminQnaDetail({
          memberId,
          productId
        });
        qnaDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };


    return {
      qnaQuery,
      qnaDetail,
      mobileFormat,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
